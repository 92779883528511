import React from 'react';
import Background from '../../assets/img/landing-background.png';
import Device from '../../assets/img/parallax/new-2.svg';
import Fade from 'react-reveal/Fade';
import useBreakpoint from '../../utils/useBreakpoint';

const LandingSection = ({ title, ...rest }) => {
  const isMobile = useBreakpoint('sm', false);
  return (
    <div
      id="landing-section-container"
      className="section-container landing-main-container"
      style={{
        position: 'relative',
        backgroundColor: 'transparent',
        minHeight: '150vh',
      }}
      {...rest}
    >
      <div
        style={{
          height: '100vh',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundImage: `url(${Background})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          zIndex: 0,
          overflow: 'hidden',
        }}
      />
      <div
        style={{
          height: '100vh',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: -2,
          overflow: 'hidden',
          backgroundColor: 'black',
        }}
      />
      <div
        className="landing-content-container"
        style={isMobile ? { display: 'block', paddingTop: 72 } : null}
      >
        <div
          className="flex-1-column-center"
          style={{ paddingTop: isMobile ? 3 + 'rem' : 80 }}
        >
          {title && (
            <Fade bottom>
              {title.split('\n').map((text, index) => (
                <div className="h3 color-white" key={index}>
                  {text}
                </div>
              ))}
            </Fade>
          )}
          <Fade bottom>
            <img
              src={Device}
              alt=""
              style={
                isMobile
                  ? { width: '90%', marginTop: 64, padding: '0 32px' }
                  : { marginTop: 64, maxWidth: 450 }
              }
            />
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default LandingSection;

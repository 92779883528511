import React from 'react';
import Fade from 'react-reveal/Fade';
import Arrow from '../assets/img/arrow-right-black.svg';

const QuoteContainer = ({ style, leftQuote, rightQuote, secondary, ...rest }) => {
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        maxWidth: 984,
        width: '100%',
        ...style,
      }}
      {...rest}
    >
      {!secondary && (
        <div style={{ display: 'flex', width: '100%', height: 135 }}>
          <div style={{ flex: 1, borderRight: '2px solid #0000001A' }} />
          <div style={{ flex: 1 }} />
        </div>
      )}
      <div style={{ display: 'flex', width: '100%' }}>
        <div
          style={{
            flex: 1,
            border: '2px solid #0000001A',
            borderLeft: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: 72,
            paddingTop: secondary ? 0 : 72,
            borderTop: secondary ? 0 : '2px solid #0000001A',
          }}
        >
          {leftQuote && (
            <Fade bottom>
              <div>
                {leftQuote.quote_image.url && (
                  <img
                    src={leftQuote.quote_image.url}
                    alt=""
                    style={{ height: 32, maxWidth: '100%', marginBottom: 48 }}
                  />
                )}
                <div
                  className="subtitle color-text-dark"
                  style={{ marginBottom: 48, maxWidth: 284 }}
                >
                  {leftQuote.quote_content}
                </div>
                <a
                  href={leftQuote.quote_button_link.url}
                  className="a-tag-no-style d-flex"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Arrow} alt="" style={{ marginRight: 16 }} />
                  <div className="paragraph color-text-dark">
                    {leftQuote.quote_button_text}
                  </div>
                </a>
              </div>
            </Fade>
          )}
        </div>
        <div
          style={{
            flex: 1,
            borderTop: secondary ? 0 : '2px solid #0000001A',
            borderBottom: '2px solid #0000001A',
            padding: 72,
            paddingTop: secondary ? 120 : 192,
          }}
        >
          {rightQuote && (
            <Fade bottom>
              <div>
                {rightQuote.quote_image.url && (
                  <img
                    src={rightQuote?.quote_image.url}
                    alt=""
                    style={{ height: 32, maxWidth: '100%', marginBottom: 48 }}
                  />
                )}
                <div
                  className="subtitle color-text-dark"
                  style={{ marginBottom: 48, maxWidth: 284 }}
                >
                  {rightQuote?.quote_content}
                </div>
                <a
                  href={rightQuote.quote_button_link.url}
                  className="a-tag-no-style d-flex"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Arrow} alt="" style={{ marginRight: 16 }} />
                  <div className="paragraph color-text-dark">
                    {rightQuote.quote_button_text}
                  </div>
                </a>
              </div>
            </Fade>
          )}
        </div>
      </div>
      <div style={{ display: 'flex', width: '100%', height: 135 }}>
        <div style={{ flex: 1, borderRight: '2px solid #0000001A' }} />
        <div style={{ flex: 1 }} />
      </div>
    </div>
  );
};

export default QuoteContainer;

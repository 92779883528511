import React, { useEffect, useRef } from 'react';
import Connect from '../../assets/img/connect.svg';
import CargeButton from '../../components/CargeButton';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Car from '../../assets/img/booking-car-new-2.png';
import NeonSign from '../../assets/img/neon-sign.svg';
import useBreakpoint from '../../utils/useBreakpoint';
import Img from 'gatsby-image';
import ReactGA from 'react-ga';

gsap.registerPlugin(ScrollTrigger);

const BookingSection = ({ subtitle, buttons, mobileImage, ...rest }) => {
  const bookingContainer = useRef();
  const bookingImagesContainer = useRef();
  const bookingImages = useRef();
  const bookingCar = useRef();
  const bookingNeonSign = useRef();
  const booking = useRef();
  const seamless = useRef();
  const subtitleRef = useRef();
  const isMobile = useBreakpoint('sm', false);

  useEffect(() => {
    if (isMobile) {
      return;
    }
    const bottomDistance =
      document.getElementById('booking-section-container').getBoundingClientRect()
        .height * 2;
    const t1 = gsap.timeline({
      scrollTrigger: {
        trigger: bookingContainer.current,
        scrub: 1,
        start: 'top top',
        end: `${bottomDistance * 2}px`,
        pin: true,
        pinSpacing: 'margin',
        id: 'booking-scroll-trigger-id',
      },
    });
    t1.set(seamless.current, {
      y: 200,
      autoAlpha: 0,
    })
      .set(booking.current, {
        y: 100,
        autoAlpha: 0,
      })
      .set(bookingCar.current, {
        y: 300,
        autoAlpha: 0,
      })
      .set(bookingNeonSign.current, {
        y: 300,
        autoAlpha: 0,
      })
      .set(subtitleRef.current, {
        y: 300,
        autoAlpha: 0,
      })
      .to(seamless.current, {
        y: 0,
        autoAlpha: 1,
        ease: 'slow',
      })
      .to(booking.current, {
        y: 0,
        autoAlpha: 1,
        ease: 'slow',
      })
      .to(
        bookingCar.current,
        {
          y: 0,
          autoAlpha: 1,
          ease: 'slow',
        },
        'phase'
      )
      .to(
        bookingNeonSign.current,
        {
          y: 0,
          autoAlpha: 1,
          ease: 'slow',
        },
        'phase'
      )
      .to(subtitleRef.current, {
        y: 0,
        autoAlpha: 1,
        ease: 'slow',
      });
  }, [
    bookingContainer,
    bookingImagesContainer,
    bookingImages,
    booking,
    bookingNeonSign,
    bookingCar,
    seamless,
  ]);

  const handleGa = (index) => {
    if (index === 0) {
      ReactGA.ga('send', 'event', 'Download', 'click', 'App Store 1');
      return;
    }
    ReactGA.ga('send', 'event', 'Download', 'click', 'Google Play 1');
  };

  return (
    <div
      style={{ minHeight: '100vh', width: '100%', display: isMobile && 'flex' }}
      ref={bookingContainer}
      id="booking-section-container"
      {...rest}
    >
      <div
        style={
          isMobile
            ? {
                display: 'flex',
                width: '100%',
              }
            : {
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: !isMobile && 104,
              }
        }
      >
        <div
          className="d-flex flex-column align-items-center container-lg"
          style={{
            position: 'relative',
            flex: isMobile && 1,
            width: '100%',
            justifyContent: isMobile && 'space-evenly',
          }}
        >
          {isMobile ? (
            <div className="booking-image-container-mobile">
              <div style={{ width: '84vw' }}>
                <div
                  className="color-text-dark paragraph"
                  style={{ textAlign: 'left', fontSize: 16, letterSpacing: 2 }}
                >
                  SEAMLESS EV
                </div>
                <img src={Connect} alt="" style={{ width: '100%' }} />
              </div>
              <Img fluid={mobileImage} alt="" className="booking-car-mobile" />
            </div>
          ) : (
            <div
              style={{ position: 'relative', marginBottom: 64 }}
              className={isMobile ? 'scaled-down' : null}
              ref={bookingImages}
            >
              <div
                style={{
                  position: 'absolute',
                  zIndex: -1,
                  top: -104,
                  left: '-25%',
                }}
              >
                <div
                  className="paragraph color-text-dark"
                  style={{ letterSpacing: 2 }}
                  ref={seamless}
                >
                  SEAMLESS EV
                </div>
                <img src={Connect} alt="" ref={booking} />
              </div>
              <img src={Car} alt="" style={{ maxWidth: 688 }} ref={bookingCar} />
              <img
                src={NeonSign}
                alt=""
                style={{ position: 'absolute', bottom: 100, right: -160, zIndex: -10 }}
                ref={bookingNeonSign}
              />
            </div>
          )}
          <div
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            ref={subtitleRef}
          >
            <div
              className="title color-text-dark"
              style={{
                maxWidth: isMobile ? '74%' : 784,
                textAlign: 'center',
                lineHeight: 1.2,
                marginBottom: 64,
              }}
            >
              {subtitle}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: isMobile ? 'column' : 'row',
              }}
            >
              {buttons &&
                buttons.map((button, index) => {
                  return (
                    <CargeButton
                      icon={
                        button.booking_button.document &&
                        button.booking_button.document.data.icon.url
                      }
                      iconStyle={isMobile ? { width: 20, height: 20 } : null}
                      link={
                        button.booking_button.document &&
                        button.booking_button.document.data.link.url
                      }
                      style={{
                        color: 'white',
                        borderRadius: 16,
                        padding: isMobile ? 28 : 32,
                        marginRight: isMobile ? null : 24,
                        marginBottom: isMobile ? 8 : null,
                      }}
                      key={index}
                      onClick={() => handleGa(index)}
                    >
                      {button.booking_button.document &&
                        button.booking_button?.document?.data?.text}
                    </CargeButton>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingSection;

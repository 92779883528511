import React, { useEffect, useState } from 'react';
import { Parallax, Background } from 'react-parallax';
import Image1 from '../../assets/img/parallax/booking-done.svg';
import Image2 from '../../assets/img/parallax/car-history.svg';
import Image3 from '../../assets/img/parallax/login.svg';
import Image4 from '../../assets/img/parallax/new-3.png';
import Image5 from '../../assets/img/parallax/profile-user.png';
import Image6 from '../../assets/img/parallax/booking-done.svg';
import useBreakpoint from '../../utils/useBreakpoint';

const ParallaxSection = ({ title, ...rest }) => {
  const [hasRun, setHasRun] = useState(false);
  const [skewValue, setSkewValue] = useState(0);
  const isMobile = useBreakpoint('sm', false);

  const handleWindowSizeChange = () => {
    setSkewValue((isMobile ? 3440 : 14400) / window.innerWidth);
  };

  useEffect(() => {
    if (!hasRun) {
      setHasRun(true);
      handleWindowSizeChange();
    }
    window.addEventListener('resize', handleWindowSizeChange);
    return window.removeEventListener('resize', handleWindowSizeChange);
  }, [skewValue]);

  return (
    <div
      id="parallax-section-container"
      style={{ height: '100vh', padding: '15vh 0' }}
      {...rest}
    >
      <div
        className="paragraph color-text-dark"
        style={{
          transform: !isMobile && `rotate(-${skewValue}deg)`,
          textAlign: 'center',
          marginBottom: isMobile ? 4 + 'rem' : 24,
          textTransform: 'uppercase',
          paddingRight: isMobile && '20%',
          paddingLeft: isMobile && '20%',
          fontSize: isMobile && 14,
        }}
      >
        {title}
      </div>
      <Parallax
        strength={300}
        style={{
          height: '100%',
          transform: `skewY(-${skewValue}deg)`,
          background: '#110935',
          display: 'flex',
        }}
        bgStyle={{
          height: '100%',
          width: '100%',
          top: '-20%',
        }}
      >
        <Background className="custom-bg">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              transform: `skewY(${skewValue}deg)`,
              position: 'absolute',
              left: 0,
              right: 0,
              top: -400,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '20%',
              }}
            >
              <img
                src={Image1}
                alt=""
                style={{ marginBottom: 48 }}
                className={isMobile ? 'paralax-image-med' : 'parallax-image-desktop'}
              />
              <img
                src={Image2}
                alt=""
                className={isMobile ? 'paralax-image-med' : 'parallax-image-desktop'}
              />
              {isMobile && (
                <>
                  <img
                    src={Image3}
                    alt=""
                    className={isMobile ? 'paralax-image-med' : undefined}
                  />
                  <img
                    src={Image4}
                    alt=""
                    className={isMobile ? 'paralax-image-med' : undefined}
                  />
                </>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '0 64px',
              }}
            >
              <img
                src={Image3}
                alt=""
                style={{ marginBottom: 48 }}
                className={isMobile ? 'paralax-image-med' : 'parallax-image-desktop'}
              />
              <img
                src={Image4}
                alt=""
                className={isMobile ? 'paralax-image-med' : 'parallax-image-desktop'}
              />
              {isMobile && (
                <>
                  <img
                    src={Image5}
                    alt=""
                    className={isMobile ? 'paralax-image-med' : undefined}
                  />
                  <img
                    src={Image6}
                    alt=""
                    className={isMobile ? 'paralax-image-med' : undefined}
                  />
                </>
              )}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '10%' }}>
              <img
                src={Image5}
                alt=""
                style={{ marginBottom: 48 }}
                className={isMobile ? 'paralax-image-med' : 'parallax-image-desktop'}
              />
              <img
                src={Image6}
                alt=""
                className={isMobile ? 'paralax-image-med' : 'parallax-image-desktop'}
              />
              {isMobile && (
                <>
                  <img
                    src={Image1}
                    alt=""
                    className={isMobile ? 'paralax-image-med' : undefined}
                  />
                  <img
                    src={Image2}
                    alt=""
                    className={isMobile ? 'paralax-image-med' : undefined}
                  />
                </>
              )}
            </div>
          </div>
        </Background>
      </Parallax>
    </div>
  );
};

export default ParallaxSection;

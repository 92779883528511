import React from 'react';
import useBreakpoint from '../../utils/useBreakpoint';
import Carousel from 'react-multi-carousel';

const responsive = {
  desktop2k: {
    breakpoint: { max: 3000, min: 1920 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 1920, min: 1440 },
    items: 4,
  },
  laptop: {
    breakpoint: { max: 1440, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 772 },
    items: 2,
  },
  mobileLarge: {
    breakpoint: { max: 772, min: 580 },
    items: 4,
  },
  mobileMed: {
    breakpoint: { max: 580, min: 420 },
    items: 3,
  },
  mobileSmall: {
    breakpoint: { max: 420, min: 270 },
    items: 2,
  },
  mobile1990: {
    breakpoint: { max: 270, min: 0 },
    items: 1,
  },
};

const AwardSection = ({ title, images, ...rest }) => {
  const isMobile = useBreakpoint('sm', false);

  return (
    <div style={isMobile ? { paddingTop: '20vh' } : {}}>
      <div
        className={isMobile ? 'd-flex' : 'vh-100 d-flex'}
        style={{
          height: isMobile && '35vh',
          padding: isMobile ? 0 : '0 80px',
        }}
        {...rest}
      >
        <div
          className="container-xl d-flex flex-column"
          style={{
            height: '100%',
            width: '100%',
            padding: isMobile ? 10 : 48,
            justifyContent: 'center',
          }}
          id="carousel-section-container"
        >
          {title && (
            <div
              className="color-text-dark h4"
              style={{
                fontSize: 42,
                marginBottom: isMobile ? 48 : 160,
                textAlign: 'center',
              }}
            >
              {title}
            </div>
          )}
          <Carousel
            responsive={responsive}
            itemClass="carousel-item-container"
            arrows={false}
          >
            {images &&
              images.length > 0 &&
              images.map((item, index) => (
                <img
                  src={item.awards_image.url}
                  alt=""
                  key={index}
                  className="carousel-image"
                  style={{ height: isMobile ? 42 : 68 }}
                />
              ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default AwardSection;

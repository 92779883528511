import React from 'react';
import QuoteContainer from '../../components/QuoteContainer';
import useBreakpoint from '../../utils/useBreakpoint';
import Fade from 'react-reveal/Fade';
import Arrow from '../../assets/img/arrow-right-black.svg';

const MobileQuoteContainer = ({ item, isLast }) => {
  return (
    <div style={{ display: 'flex', width: '70%' }}>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          alignSelf: 'center',
          paddingTop: 0,
          borderTop: 0,
          marginBottom: !isLast && 5 + 'rem',
        }}
      >
        <Fade bottom>
          <img
            src={item?.quote_image?.url}
            alt=""
            style={{ height: 28, marginBottom: 32 }}
          />
          <div className="subtitle color-text-dark" style={{ marginBottom: 32 }}>
            {item?.quote_content}
          </div>
          <a style={{ display: 'flex' }} href={item?.quote_button_link?.url}>
            <img src={Arrow} alt="" style={{ marginRight: 16 }} />
            <div className="paragraph-left color-text-dark">
              {item?.quote_button_text}
            </div>
          </a>
        </Fade>
      </div>
    </div>
  );
};

const QuoteSection = ({ quotes, ...rest }) => {
  const isMobile = useBreakpoint('sm', false);

  const chunkArray = (array, size) => {
    if (!array) {
      return;
    }
    const results = [];
    while (array.length) {
      results.push(array.splice(0, size));
    }
    return results;
  };
  const allQuotes = [...quotes];
  const groupedQuotes = chunkArray(allQuotes, 2);
  return (
    <div
      id="quote-section-container"
      className="d-flex flex-column align-items-center"
      style={{ minHeight: '100vh', padding: '160px 0', scrollSnapAlign: 'start' }}
      {...rest}
    >
      {!isMobile &&
        groupedQuotes?.length > 0 &&
        groupedQuotes?.map((items, index) => {
          return (
            <QuoteContainer
              leftQuote={items[0]}
              rightQuote={items[1]}
              secondary={index > 0}
              key={index}
            />
          );
        })}
      {isMobile &&
        quotes?.map((item, index, arr) => {
          return (
            <MobileQuoteContainer
              item={item}
              isLast={index === arr.length - 1}
              key={index}
            />
          );
        })}
    </div>
  );
};

export default QuoteSection;

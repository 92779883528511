import React, { useState } from 'react';
import ReactPlayer from 'react-player';

const QuoteSection = ({ ...rest }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  return (
    <div style={{ height: '100vh', position: 'relative' }} {...rest}>
      <ReactPlayer
        url="https://www.youtube.com/embed/DvsG2y5jiqk"
        playing={isPlaying}
        width="100%"
        height="80%"
        style={{ position: 'absolute', zIndex: 0, bottom: -1 }}
      />
      <div
        style={{
          width: '100%',
          height: '80%',
          position: 'absolute',
          zIndex: 10,
          cursor: 'pointer',
          bottom: 0,
        }}
        onClick={() => setIsPlaying(!isPlaying)}
      />
    </div>
  );
};

export default QuoteSection;

import React from 'react';
import Arrow from '../../assets/img/arrow-right-black.svg';
import Fade from 'react-reveal/Fade';
import useBreakpoint from '../../utils/useBreakpoint';
import ReactGA from 'react-ga';

const AboutSection = ({ title, subtitle, buttonText, link, ...rest }) => {
  const isMobile = useBreakpoint('sm', false);

  const handleTitle = (text, index) => {
    if (index === 1) {
      return text.split(' ').map((item, idx) => {
        if (idx === 0) {
          return item;
        }
        return (
          <span className="color-primary-dark" key={idx}>
            {' '}
            {item}
          </span>
        );
      });
    }
    if (index === 2) {
      return <span className="color-primary-dark">{text}</span>;
    }
    return text;
  };

  const handleGa = () => {
    ReactGA.ga('send', 'event', 'Download', 'click', 'Charge your EV for free');
  };

  return (
    <div
      className={
        isMobile
          ? 'section-container--mobile about-section-container justify-content-start align-items-center'
          : 'section-container about-section-container'
      }
      id="about-section-container"
      {...rest}
    >
      <div
        className={
          isMobile
            ? 'd-flex flex-column align-items-start justify-content-start'
            : 'd-flex justify-content-between flex-1 container-lg'
        }
        style={
          isMobile
            ? {
                width: '70%',
              }
            : null
        }
      >
        <Fade bottom>
          <div
            className={
              isMobile
                ? 'd-flex justify-content-start '
                : 'flex-1 d-flex justify-content-start'
            }
            style={isMobile ? { marginBottom: 2.5 + 'rem' } : { paddingTop: 48 }}
          >
            <div
              className="h1 color-text-dark"
              style={isMobile ? { textAlign: 'left' } : null}
            >
              {title &&
                title.split('\n').map((text, index) => {
                  return <div key={index}>{handleTitle(text, index)}</div>;
                })}
            </div>
          </div>
        </Fade>
        <Fade bottom>
          <div className="d-flex flex-column justify-content-center">
            <div
              className="title color-text-dark"
              style={{
                lineHeight: 1.3,
                marginBottom: isMobile ? 3.5 + 'rem' : 80,
                maxWidth: !isMobile && 560,
                textAlign: isMobile && 'left',
              }}
            >
              {subtitle}
            </div>
            {buttonText && (
              <a
                href={link}
                className="a-tag-no-style d-flex"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => handleGa()}
              >
                <img src={Arrow} alt="" style={{ marginRight: 16 }} />
                <div
                  className="paragraph color-text-dark"
                  style={{
                    fontSize: isMobile ? 18 : 22,
                    textAlign: isMobile ? 'left' : null,
                  }}
                >
                  {buttonText}
                </div>
              </a>
            )}
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default AboutSection;

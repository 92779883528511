import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import useBreakpoint from '../../utils/useBreakpoint';
import Img from 'gatsby-image';
gsap.registerPlugin(ScrollTrigger);

const DeviceMapSection = ({ image, ...rest }) => {
  const deviceRef = useRef();
  const deviceMainRef = useRef();
  const isMobile = useBreakpoint('sm', false);

  useEffect(() => {
    if (isMobile) {
      return;
    }
    const t1 = gsap.timeline({
      scrollTrigger: {
        trigger: deviceMainRef.current,
        scrub: 1,
        start: 'top top',
        end: 'bottom',
        pin: true,
        pinSpacing: 'margin',
      },
    });
    t1.to(deviceRef.current, {
      scale: 0.8,
    });
  }, [deviceMainRef, deviceRef]);

  return (
    <>
      <div
        style={{ minHeight: isMobile ? '60vh' : '100vh' }}
        ref={deviceMainRef}
        {...rest}
      >
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxHeight: '100vh',
          }}
        >
          <div
            style={{
              width: '100%',
              maxWidth: 1920,
              display: 'flex',
              justifyContent: 'center',
            }}
            ref={deviceRef}
            id="device-section-container"
          >
            <Img
              fluid={image}
              alt=""
              style={{
                height: isMobile ? '250%' : 'fit-content',
                width: isMobile ? '250%' : '100%',
                maxWidth: !isMobile && '100%',
                maxHeight: !isMobile && '100vh',
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DeviceMapSection;

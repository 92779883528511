import React from 'react';
import LandingSection from '../sections/home/LandingSection';
import AboutSection from '../sections/home/AboutSection';
import NavigationSection from '../sections/home/NavigationSection';
import BookingSection from '../sections/home/BookingSection';
import CarouselSection from '../sections/home/CarouselSection';
import ParallaxSection from '../sections/home/ParallaxSection';
import QuoteSection from '../sections/home/QuoteSection';
import MediaSection from '../sections/home/MediaSection';
import DeviceMapSection from '../sections/home/DeviceMapSection';
import Seo from '../components/SEO';
import { graphql } from 'gatsby';
import AwardSection from '../sections/home/AwardSection';

const HomePage = ({ pageContext, data }) => {
  if (!pageContext) return '';

  const pageLang = Object.keys(pageContext)[0];
  const pageData = Object.values(pageContext)[0];

  return (
    <div>
      <Seo
        title={pageData?.meta_title}
        description={pageData?.meta_description}
        image={pageData?.meta_image?.url}
        lang={pageLang}
      />
      <LandingSection title={pageData.landing_title} />
      <AboutSection
        title={pageData.about_title}
        subtitle={pageData.about_subtitle}
        buttonText={pageData.about_button_text}
        link={pageData.about_button_link.url}
      />
      <NavigationSection
        title={pageData.navigation_title}
        subtitle={pageData.navigation_subtitle}
      />
      <DeviceMapSection image={data?.navigationCards?.childImageSharp?.fluid} />
      <BookingSection
        subtitle={pageData.booking_subtitle}
        buttons={pageData.booking_buttons}
        mobileImage={data?.bookingCar?.childImageSharp?.fluid}
      />
      <CarouselSection images={pageData.carousel_group} />
      <ParallaxSection title={pageData.parallax_title} />
      <AwardSection title={pageData.awards_title} images={pageData.awards_group} />
      <QuoteSection quotes={pageData.quote_group} />
      <MediaSection />
    </div>
  );
};

export default HomePage;

export const query = graphql`
  query {
    bookingCar: file(relativePath: { eq: "img/booking-car-new-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    navigationCards: file(relativePath: { eq: "img/navigation-cards-new.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

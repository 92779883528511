import React from 'react';
import NavigationMap from '../../assets/img/navigation-map.svg';
import useBreakpoint from '../../utils/useBreakpoint';

const NavigationSection = ({ title, subtitle, ...rest }) => {
  const isMobile = useBreakpoint('sm', false);
  return (
    <div
      id="navigation-section-container"
      className={isMobile ? 'section-container--mobile' : 'section-container'}
      {...rest}
    >
      <div
        className="d-flex flex-1 container-xl"
        style={{
          height: isMobile ? null : '70vh',
          padding: isMobile ? 0 : null,
          flex: isMobile && 5,
        }}
      >
        <div className="flex-1-column-center" style={{ position: 'relative' }}>
          <div
            style={{
              position: 'absolute',
              width: '100%',
              overflow: 'hidden',
              display: 'flex',
              height: '100%',
            }}
          >
            <div style={{ position: 'relative', width: '100%' }}>
              <img
                src={NavigationMap}
                alt=""
                style={{
                  position: 'absolute',
                  width: isMobile && '120%',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 0,
                }}
              />
            </div>
          </div>
          {title &&
            title.split('\n').map((text, index) => (
              <div className="h2 color-text-dark" style={{ zIndex: 1 }} key={index}>
                {text}
              </div>
            ))}
        </div>
      </div>
      <div
        className="d-flex justify-content-center"
        style={{ flex: isMobile ? 1 : null }}
      >
        <div
          className="title text-center color-text-dark"
          style={{ maxWidth: isMobile ? '65%' : 784, lineHeight: 1.3 }}
        >
          {subtitle}
        </div>
      </div>
    </div>
  );
};

export default NavigationSection;
